import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '@shared/session.service';
import { Subject } from 'rxjs';
import { environment } from '@app-env';
import { AUTH_TOKEN_VERSION } from '@shared/auth/classes/user';

@Injectable()
export class AuthService {
    loadingSubject: Subject<boolean> = new Subject();
    clientId!: string;

    constructor(private httpService: HttpClient, private sessionService: SessionService, route: ActivatedRoute) {
        route.queryParams.subscribe((params: any) => {
            this.clientId = params.client_id;
        });
    }

    loading(state: boolean) {
        this.loadingSubject.next(state);
    }

    me() {
        return this.httpService.get(environment.authenticationUrl + 'authentication/headless/me');
    }

    private async generateStateAndGetAuthenticationUrl(): Promise<string> {
        const redirectUrl = window.location.origin + '/login';

        const url =
            environment.authenticationUrl +
            'authentication/url?redirectUrl=' +
            encodeURIComponent(redirectUrl) +
            '&clientId=' +
            encodeURIComponent(environment.clientId);

        const data = (await this.httpService.get(url).toPromise()) as {
            url: string;
            state: string;
        };

        localStorage.setItem('authentication-state', data.state);
        return data.url;
    }

    async redirectToLogin(): Promise<void> {
        // Logout param added just to be sure central auth cleans previous datastore (complete full logout)
        const url = await this.generateStateAndGetAuthenticationUrl();
        window.location.href = url + '&logout=true';
    }

    async redirectToLogout(): Promise<void> {
        this.sessionService.clear();
        const url = await this.generateStateAndGetAuthenticationUrl();
        window.location.href = url + '&logout=true';
    }

    async exchangeGrantCodeAndSetAuthToken(code: string, state: string): Promise<boolean> {
        if (!code || !state || localStorage.getItem('authentication-state') !== state) {
            return false;
        }

        localStorage.removeItem('authentication-state');

        const url = environment.authenticationUrl + 'authentication/headless/token';

        let data;

        try {
            data = (await this.httpService
                .post(url, {
                    code: code,
                    clientId: environment.clientId,
                    clientKey: environment.clientKey,
                })
                .toPromise()) as { token: string };

            if (data === undefined) {
                data = { token: 'super-user' };
            }
        } catch (_err) {
            return false;
        }

        // This is to be compatible with the existing system, which
        // may not be what we want to do in the future -- maybe we
        // will even go all crazy and set a cookie!

        this.sessionService.set({
            token: data.token,
            version: AUTH_TOKEN_VERSION,
        });
        return true;
    }

    authHeaders(): Record<string, string> {
        const session = this.sessionService.get();
        if (!session || !session.token) {
            // stale session, prompt user to login
            this.redirectToLogin();
            return {};
        }
        return {
            Authorization: session.token,
        };
    }

    isLoggedIn(): boolean {
        if(this.sessionService.get().token && this.sessionService.get().organisation?.id) return true;
        return false;
    }

    hasUserExtendedData(): boolean {
        if(this.sessionService.get().organisation?.id) return true;
        return false;
    }
}
